
import {defineComponent, ref, onMounted, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {injectOrThrow} from "@/helpers/inject";
import {Searches} from "@/models";
import {useForm, useField} from 'vee-validate';
import {useRouter, useRoute} from "vue-router";

export default defineComponent({
	props: {
		items: {
			type: Array as PropType<{id: string, name: string, urlName: string}[]>,
			required: true
		}
	},
	setup(props) {
		const { t } = useI18n();
		const {handleSubmit} = useForm();
		const route = useRoute();
		const router = useRouter();

		const service = injectOrThrow<Searches.SearchService>('Searches.SearchService');

		const listTables = ref([] as Searches.TableName[])
		const getListTables = async () => {
			const response = await service.listTables();
			listTables.value = response.map(table => {
				const isTable = props.items.find(item => item.id === table.id);

				return {
					...table,
					name: isTable?.name ?? table.table_name
				}
			});

			const isSelectTableName = listTables.value.find(table => table.id === route.query.table)

			if(isSelectTableName)
				selectTableName(isSelectTableName)
		}

		const listColumns = ref([] as Searches.ColumnName[])

		onMounted(async () => await getListTables());

		const { value: selectedTableName, errorMessage: tableNameError } = useField<Searches.TableName>('selectedTableName', 'required');
		async function selectTableName(selected: Searches.TableName) {
			selectedTableName.value = selected ?? {};

			if(selectedTableName.value.id) {
				listColumns.value = await service.listFields(selectedTableName.value.id);

				const isSelectColumnName = listColumns.value.find(column => column.id === route.query.field)

				if(isSelectColumnName) {
					selectColumnName(isSelectColumnName);
					value.value = typeof route.query.value === 'string' ? route.query.value : "";
				}
			}
		}

		const { value: selectedColumnName, errorMessage: columnNameError } = useField<Searches.ColumnName>('selectedColumnName', 'required');
		async function selectColumnName(selected: Searches.ColumnName) {
			selectedColumnName.value = selected ?? {};
		}

		const { value: value, errorMessage: valueError } = useField<string>('value', 'required');

		const search = handleSubmit(async () => {
			return router.replace({ query: {
				table: selectedTableName.value.table_name,
				field: selectedColumnName.value.column_name,
				value: value.value,
				data_type: selectedColumnName.value.data_type
			} });
		});

		return {
			t,
			listTables, selectedTableName, selectTableName, tableNameError,
			listColumns, selectedColumnName, selectColumnName, columnNameError,
			value, valueError,
			search
		}

	}
})
