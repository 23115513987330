
import {defineComponent, defineAsyncComponent, watch, ref, shallowRef} from 'vue';
import {useI18n} from 'vue-i18n';
import Search from "./Search.vue";
import {useRoute} from "vue-router";

export default defineComponent({
	components: {
		Search
	},
	setup() {
		const { t } = useI18n();
		const route = useRoute();

		const items = ref([
			{ id: 'dict.group', name: t('title.groups'), urlName: 'groups' },
			{ id: 'dict.enterprise', name: t('title.enterprises'), urlName: 'enterprises' },
			{ id: 'dict.type_tag', name: t('title.typeTags'), urlName: 'typeTags' },
			{ id: 'dict.type_res', name: t('title.typeResources'), urlName: 'typeResources' },
			{ id: 'dict.type_label', name: t('title.typeLabels'), urlName: 'typeLabels' },
			{ id: 'dict.kind_type_res', name: t('title.kindTypeResources'), urlName: 'kindTypeResources' },
			{ id: 'dict.legal_pair', name: t('title.legalPairs'), urlName: 'legalPairs' },
			{ id: 'dict.legal_pair_links', name: t('title.rules'), urlName: 'rules' },
			{ id: 'app.tags', name: t('title.tags'), urlName: 'tags' },
			{ id: 'app.resources', name: t('title.resources'), urlName: 'resources' }
		])

		const isComponent = shallowRef();

		watch(() => route.query, async (query) => {
			isComponent.value = defineAsyncComponent(() => {
				const isTable = items.value.find(item => item.id === query.table);

				if(isTable) {
					return import(`@/views/${isTable.urlName}/Listing.vue`)
				} else {
					return import(`@/views/404.vue`)
				}
			});
		}, { immediate: true });

		return { t, route, items, isComponent }
	}
})
