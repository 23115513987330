import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container my-4 app-search" }
const _hoisted_2 = { class: "row tablet-show-ai-center mb-4 tablet-hide-fd-column-reverse sticky" }
const _hoisted_3 = { class: "col py-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search = _resolveComponent("search")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.t("title.searches")), 1)
        ])
      ]),
      _createVNode(_component_search, { items: _ctx.items }, null, 8, ["items"])
    ]),
    (Object.keys(_ctx.route.query).length)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isComponent), { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}